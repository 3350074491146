// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// Overrides
@import './overrides.scss';

// Globals
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  font-family: 'Playfair Display', serif;
}

a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
