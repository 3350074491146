a:hover {
  @apply text-accent;
}

.white-labels {
  .ant-form-item-label > label,
  .ant-radio-wrapper {
    @apply text-white;
  }
}

.ant-message {
  position: fixed;
  bottom: 20px !important;
  top: unset !important;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-modal-content {
  @apply border-accent;

  border: 10px solid #C5B27C;
  border-radius: 0;
}

.skip-uploads-list .ant-upload-list {
  display: none;
}

.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.ant-switch {
  @apply border border-accent;

  border-style: solid;
  height: 24px;
}

.ant-switch-checked .ant-switch-inner {
  @apply text-white;
}

.ant-btn > .ant-btn-loading-icon .anticon {
  display: block;
}

.ant-form-item-label > label .ant-form-item-optional {
  @apply text-white/60;
}

.ant-radio-inner,
.ant-pagination-item-active,
.ant-pagination-item:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active,
.ant-radio-checked .ant-radio-inner,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-select-focused:not(.ant-select-disabled) .ant-select-selector,
.ant-picker-focused,
.ant-picker:hover,
.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover,
.ant-input-number-affix-wrapper:focus,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  @apply border-accent;
}

.ant-radio-inner::after,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-switch-checked {
  @apply bg-accent;
}

.ant-switch-checked .ant-switch-loading-icon {
  @apply text-accent;
}

.ant-btn-primary:focus,
.ant-btn-primary:hover, {
  @apply bg-accentDark;
}

.a:hover {
  @apply text-accentDark;
}

.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox-checked::after,
.ant-input:focus,
.ant-btn-primary:hover {
  @apply border-accentDark;
}

.ant-input:focus {
  @apply shadow-accentDark;
}

.ant-btn-link, .ant-picker-today-btn,
.ant-typography-copy,
.ant-pagination-item-active a,
.ant-pagination-item:hover a,
.ant-switch-inner {
  @apply text-accent;
}

.ant-input:hover,
.ant-input:active, {
  @apply border-accent;
}

.ant-btn-text {
  @apply inline-flex items-center;
}

.ant-btn-primary {
  @apply bg-accent border-accent shadow-none rounded-md hover:bg-accentDark;

  text-shadow: none;
}

.ant-btn-default:hover {
  @apply border-accent text-accent;
}

.ant-checkbox-checked .ant-checkbox-inner {
  @apply bg-accent border-accent;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  @apply border-accent;
}

.requirements {
  h1, h2 {
    @apply text-2xl mt-3;

    &:first-child {
      @apply mt-0;
    }

    & + p,
    & + ul {
      @apply mt-1;
    }
  }

  h2 {
    @apply text-xl;
  }

  p + p,
  ul + p,
  ul + ol,
  ol + ul, {
    @apply mt-4;
  }

  ul {
    list-style: inside;
  }

  h2 {
    @apply text-lg;
  }

  ol {
    list-style: auto;
    padding-left: 18px;
  }

  a {
    @apply underline font-semibold text-accent;
  }

  blockquote {
    @apply border-l-4 border-accent pl-4;
  }
}
